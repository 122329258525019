import { GameDto } from "dtos/GameDto";
import { useState, useCallback, useEffect } from "react";

export const useGamesData = (props: { data?: Array<GameDto> }) => {
    const { data } = props;

    const [games, setGames] = useState<Array<GameDto>>();
    const [gamesDictionary, setGamesDictionary] = useState<Map<number, GameDto>>(new Map<number, GameDto>());

    const getGame = useCallback((id: number) => gamesDictionary.get(id), [gamesDictionary]);

    useEffect(() => {
        if (data) {
            setGames(data);
            setGamesDictionary(new Map(data.map(g => [g.id, g])));
        }
    }, [data]);

    return {
        games,
        getGame,
    };
};
