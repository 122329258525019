import { LadderContext } from "contexts/LadderContext";
import { useContext } from "react";

export const DebugFooter = () => {
    const { debugMessages } = useContext(LadderContext);

    if (!localStorage.getItem("Shitashi")) {
        return null;
    }

    return (
        <div className="pb-5 pb-lg-0">
            <div className="text-bg-primary p-3">Debug</div>

            {debugMessages.map(({ id, name }) => (
                <div className="hstack gap-3 p-3 text-bg-warning border" key={id}>
                    <div>#{id}</div>

                    <div className="text-break">{name}</div>
                </div>
            ))}
        </div>
    );
};
