import { LadderContext } from "contexts/LadderContext";
import { useContext } from "react";

export const ReconnectingBanner = () => {
    const { hubState } = useContext(LadderContext);

    if (hubState === "Reconnecting") {
        return (
            <div className="text-bg-danger">
                <div className="container">
                    <div className="py-2 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                        <div className="hstack gap-2 align-self-center">
                            <div>
                                <span className="fa-solid fa-spinner fa-spin-pulse"></span>
                            </div>

                            <strong>Reconnecting</strong>
                        </div>

                        <div className="small">Your connection has been lost</div>
                    </div>
                </div>
            </div>
        );
    }

    return null;
};
