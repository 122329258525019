import { useEffect } from "react";

export const useTheme = () => {
    useEffect(() => {
        const getStoredTheme = () => localStorage.getItem("theme");
        const setStoredTheme = (theme: string) => localStorage.setItem("theme", theme);

        const getPreferredTheme = () => {
            const storedTheme = getStoredTheme();
            if (storedTheme) {
                return storedTheme;
            }

            return window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
        };

        const setTheme = (theme: string) => {
            if (theme === "auto" && window.matchMedia("(prefers-color-scheme: dark)").matches) {
                document.documentElement.setAttribute("data-bs-theme", "dark");
            } else {
                document.documentElement.setAttribute("data-bs-theme", theme);
            }
        };

        setTheme(getPreferredTheme());

        const showActiveTheme = (theme: string, focus = false) => {
            const themeSwitcher = document.querySelector("#bd-theme");

            if (!themeSwitcher) {
                return;
            }

            const themeSwitcherText = document.querySelector("#bd-theme-text");
            const activeThemeIcon = document.querySelector(".theme-icon-active use");
            const btnToActive = document.querySelector(`[data-bs-theme-value="${theme}"]`);

            if (!btnToActive) {
                return;
            }

            const svgUse = btnToActive.querySelector("svg use");

            if (!svgUse) {
                return;
            }

            const svgOfActiveBtn = svgUse.getAttribute("href");

            document.querySelectorAll("[data-bs-theme-value]").forEach(element => {
                element.classList.remove("active");
                element.setAttribute("aria-pressed", "false");
            });

            btnToActive.classList.add("active");
            btnToActive.setAttribute("aria-pressed", "true");

            if (!activeThemeIcon) {
                return;
            }

            if (!svgOfActiveBtn) {
                return;
            }

            if (!themeSwitcherText) {
                return;
            }

            // if (!btnToActive.dataset) {
            //     return;
            // }

            activeThemeIcon.setAttribute("href", svgOfActiveBtn);
            // const themeSwitcherLabel = `${themeSwitcherText.textContent} (${btnToActive.dataset.bsThemeValue})`;
            // themeSwitcher.setAttribute("aria-label", themeSwitcherLabel);

            // if (focus) {
            //     themeSwitcher.focus();
            // }
        };

        window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", () => {
            const storedTheme = getStoredTheme();
            if (storedTheme !== "light" && storedTheme !== "dark") {
                setTheme(getPreferredTheme());
            }
        });

        window.addEventListener("DOMContentLoaded", () => {
            showActiveTheme(getPreferredTheme());

            document.querySelectorAll("[data-bs-theme-value]").forEach(toggle => {
                toggle.addEventListener("click", () => {
                    const theme = toggle.getAttribute("data-bs-theme-value");

                    if (theme) {
                        setStoredTheme(theme);
                        setTheme(theme);
                        showActiveTheme(theme, true);
                    }
                });
            });
        });
    }, []);
}