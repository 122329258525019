import { BrowserRouter } from "react-router-dom";
import { GlobalContextProvider } from "contexts/GlobalContext";
import { Routes } from "components/Routes";
import { LadderContextProvider } from "contexts/LadderContext";

export const App = () => (
    <BrowserRouter>
        <GlobalContextProvider>
            <LadderContextProvider>
                <Routes />
            </LadderContextProvider>
        </GlobalContextProvider>
    </BrowserRouter>
);

export default App;
