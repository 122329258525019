import { LazyExoticComponent, lazy } from "react";

const HomePage = lazy(() => import("pages/HomePage"));
const LadderLobbyPage = lazy(() => import("pages/LadderLobbyPage"));
const LadderMatchesPage = lazy(() => import("pages/LadderMatchesPage"));
const LadderCharactersPage = lazy(() => import("pages/LadderCharactersPage"));
const LadderLeaderboardPage = lazy(() => import("pages/LadderLeaderboardPage"));
const LadderEntrantPage = lazy(() => import("pages/LadderEntrantPage"));
const ProfilePage = lazy(() => import("pages/ProfilePage"));
const AccountLoginPage = lazy(() => import("pages/account/AccountLoginPage"));
const NotificationAcceptChallengePage = lazy(() => import("pages/notification/NotificationAcceptChallengePage"));
const NotificationRejectChallengePage = lazy(() => import("pages/notification/NotificationRejectChallengePage"));
const NotificationAcceptQueuePage = lazy(() => import("pages/notification/NotificationAcceptQueuePage"));
const NotificationRejectQueuePage = lazy(() => import("pages/notification/NotificationRejectQueuePage"));

export const RouteList = {
    AccountLogin: "/login",
    Profile: "/profile",
    Home: "/",
};

interface IRouteTableItem {
    path: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Component: LazyExoticComponent<any>;
}

export const RouteTable: Array<IRouteTableItem> = [
    { path: "/ladders/:id([0-9]+)/matches", Component: LadderMatchesPage },
    { path: "/ladders/:id([0-9]+)/characters", Component: LadderCharactersPage },
    { path: "/ladders/:id([0-9]+)/leaderboard", Component: LadderLeaderboardPage },
    { path: "/ladders/:id([0-9]+)/lobby", Component: LadderLobbyPage },
    { path: "/ladders/:ladderId([0-9]+)/leaderboard/:entrantId([0-9]+)", Component: LadderEntrantPage },
    {
        path: "/notifications/accept-challenge/:ladderId([0-9]+)/:challengeId([0-9]+)",
        Component: NotificationAcceptChallengePage,
    },
    {
        path: "/notifications/reject-challenge/:ladderId([0-9]+)/:challengeId([0-9]+)",
        Component: NotificationRejectChallengePage,
    },
    {
        path: "/notifications/accept-queue/:ladderId([0-9]+)",
        Component: NotificationAcceptQueuePage,
    },
    {
        path: "/notifications/reject-queue/:ladderId([0-9]+)",
        Component: NotificationRejectQueuePage,
    },
    { path: RouteList.Home, Component: HomePage },
    { path: RouteList.AccountLogin, Component: AccountLoginPage },
    { path: RouteList.Profile, Component: ProfilePage },
];

export const RouteLink = {
    ladderLobby: (id: number) => `/ladders/${id}/lobby`,
    ladderLeaderboard: (id: number, characterId?: number) =>
        `/ladders/${id}/leaderboard${characterId ? `?c=${characterId}` : ""}`,
    ladderMatches: (id: number, characterId?: number) =>
        `/ladders/${id}/matches${characterId ? `?c=${characterId}` : ""}`,
    ladderCharacters: (id: number) => `/ladders/${id}/characters`,
    ladderEntrant: (ladderId: number, entrantId: number) => `/ladders/${ladderId}/leaderboard/${entrantId}`,
    notificationAcceptChallenge: (ladderId: number, challengeId: number) =>
        `/notifications/accept-challenge/${ladderId}/${challengeId}`,
    notificationRejectChallenge: (ladderId: number, challengeId: number) =>
        `/notifications/reject-challenge/${ladderId}/${challengeId}`,
    notificationAcceptQueue: (ladderId: number) => `/notifications/accept-queue/${ladderId}`,
    notificationRejectQueue: (ladderId: number) => `/notifications/reject-queue/${ladderId}`,
};
