import { GetLadderPlayerStateResult } from "results/ladders/GetLadderPlayerStateResult";
import { GetLadderResult } from "results/ladders/GetLadderResult";

type LadderStatus = "Open" | "InQueue" | "ChallengingPlayer" | "MatchFound" | "ChallengeReceived" | "Banned" | "";

export const useLadderStatus = (props: { ladder?: GetLadderResult; playerState?: GetLadderPlayerStateResult }) => {
    const { playerState } = props;

    let ladderStatus: LadderStatus = "";

    switch (true) {
        case playerState?.userIsBanned:
            ladderStatus = "Banned";
            break;
        case !!playerState?.pairing:
            ladderStatus = "MatchFound";
            break;
        case playerState?.challenge?.userHasChallengeSent:
            ladderStatus = "ChallengingPlayer";
            break;
        case playerState?.challenge?.userHasChallengeReceived:
            ladderStatus = "ChallengeReceived";
            break;
        case playerState?.userIsQueued:
            ladderStatus = "InQueue";
            break;
        case !!playerState:
            ladderStatus = "Open";
            break;
    }

    return { ladderStatus };
};
