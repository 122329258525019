import * as signalR from "@microsoft/signalr";
import { ReactNode, createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { GetLadderStateResult } from "results/ladders/GetLadderStateResult";
import { Constants } from "utils/Constants";
import { accessTokenFactory } from "../hooks/useAuth";
import { LadderChatMessageDto } from "dtos/LadderChatMessageDto";
import { GetLadderPlayerStateResult } from "results/ladders/GetLadderPlayerStateResult";
import { GlobalContext } from "contexts/GlobalContext";
import { GetLadderResult } from "results/ladders/GetLadderResult";
import { GetLadderPlayerStateChallengeResult } from "results/ladders/GetLadderPlayerStateChallengeResult";
import { GetLadderPlayerStateCancellationResult } from "results/ladders/GetLadderPlayerStateCancellationResult";
import { GetLadderPlayerStatePairingResult } from "results/ladders/GetLadderPlayerStatePairingResult";
import { GetLadderPlayerStateConfirmationResult } from "results/ladders/GetLadderPlayerStateConfirmationResult";
import { useLadderStatus } from "hooks/useLadderStatus";
import { LadderMatchCompletedResult } from "results/ladders/LadderMatchCompletedResult";
import { Modal } from "bootstrap";
import Logo192 from "assets/img/logo192.png";
import Logo24 from "assets/img/logo24.png";
import { RouteLink } from "Routes";
import { HubConnectionState } from "@microsoft/signalr";

const hideActiveModals = () => {
    const modals = document.querySelectorAll(".modal.show");

    for (const m of modals) {
        const modal = Modal.getOrCreateInstance(m);

        modal.hide();
    }
};

export const useLadderContext = () => {
    const { service, catchServerError } = useContext(GlobalContext);
    const { preconnect } = service;

    const hub = useRef<signalR.HubConnection>();
    const [hubState, setHubState] = useState<HubConnectionState>(HubConnectionState.Connecting);

    const [ladder, setLadder] = useState<GetLadderResult>();
    const [ladderState, setLadderState] = useState<GetLadderStateResult>();
    const [playerState, setPlayerState] = useState<GetLadderPlayerStateResult>();
    const [isConnectingToLadder, setIsConnectingToLadder] = useState(false);
    const [isMatchCompleted, setIsMatchCompleted] = useState(false);
    const [isMatchReporting, setIsMatchReporting] = useState(false);
    const [showChallengeDeclined, setShowChallengeDeclined] = useState(false);
    const [showCancellationDeclined, setShowCancellationDeclined] = useState(false);
    const [showMatchCancelled, setShowMatchCancelled] = useState(false);
    const [showMatchAutoCancelled, setShowMatchAutoCancelled] = useState(false);
    const [showEntrantDeclinedMatch, setShowEntrantDeclinedMatch] = useState(false);
    const [showOpponentDeclinedMatch, setShowOpponentDeclinedMatch] = useState(false);
    const [completedResult, setCompletedResult] = useState<LadderMatchCompletedResult>();

    const { ladderStatus } = useLadderStatus({ ladder, playerState });

    // clear ladder state
    const resetLadderState = () => {
        setLadder(undefined);
        setLadderState(undefined);
        setPlayerState(undefined);
    };

    // debug log
    const [debugMessages, setDebugMessages] = useState<Array<{ id: number; name: string }>>([]);

    const consoleLog = (message: string) => {
        if (localStorage.getItem("Shitashi")) {
            // eslint-disable-next-line no-console
            console.log(`DEBUG: ${message}`);
        }

        setDebugMessages(old => [{ id: old.length + 1, name: message }, ...old]);
    };

    const notify = (props: NotificationOptions & { title: string }) => {
        const { title } = props;

        navigator.serviceWorker.getRegistration().then(registration => {
            const isFocused = document.hasFocus();

            if (registration && !isFocused) {
                registration.showNotification(title, {
                    icon: Logo192,
                    badge: Logo24,
                    ...props,
                });
            }
        });
    };

    const handlePreconnect = useCallback(() => {
        if (ladder?.ladderId) {
            // once connected, get game state
            preconnect(ladder.ladderId)
                .then(x => {
                    consoleLog("Updating ladder and player state");
                    setLadderState(x.ladderState);
                    setPlayerState(x.playerState);
                })
                .catch(catchServerError);
        }
    }, [catchServerError, preconnect, ladder?.ladderId]);

    // perform initial preconnect
    useEffect(() => {
        if (ladder && !ladderState) {
            handlePreconnect();
        }
    }, [handlePreconnect, ladder, ladderState]);

    // web lock (experimental)
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let lockResolver;

        if (navigator && navigator.locks && navigator.locks.request) {
            const promise = new Promise(res => {
                lockResolver = res;
            });

            navigator.locks.request("unique_lock_name", { mode: "shared" }, () => promise);
        }
    }, []);

    // web socket connection
    useEffect(() => {
        if (ladder && !hub.current) {
            consoleLog("Building web socket connection");

            // builds the SignalR connection
            const hubConnection = new signalR.HubConnectionBuilder()
                .withUrl(`/hubs/ladders?ladderId=${ladder.ladderId}`, { accessTokenFactory })
                .configureLogging(Constants.signalRLogLevel)
                .withAutomaticReconnect()
                .build();

            const startHub = async () => {
                await hubConnection
                    .start()
                    .then(() => {
                        setIsConnectingToLadder(false);
                    })
                    .catch(() => {
                        consoleLog("Error in connecting");

                        resetLadderState();
                    });
            };

            hubConnection.onclose(async () => {
                setHubState(HubConnectionState.Disconnected);
            });

            hubConnection.onreconnecting(async () => {
                setHubState(HubConnectionState.Reconnecting);
            });

            hubConnection.onreconnected(async () => {
                setHubState(HubConnectionState.Connected);

                handlePreconnect();
            });

            hubConnection.on("AddLadderChatMessage", (message: LadderChatMessageDto) => {
                setLadderState(old => old && { ...old, messages: [message, ...old.messages] });
            });

            hubConnection.on("AddPairingChatMessage", (message: LadderChatMessageDto) => {
                setPlayerState(
                    old =>
                        old?.pairing?.messages && {
                            ...old,
                            pairing: { ...old.pairing, messages: [message, ...old.pairing.messages] },
                        },
                );
            });

            hubConnection.on("UpdateActiveUserCount", (activeUserCount: number) => {
                setLadderState(old => old && { ...old, activeUserCount });
            });

            hubConnection.on("SetIsQueued", () => {
                setPlayerState(old => old && { ...old, userIsQueued: true });
            });

            hubConnection.on("SetIsUnqueued", () => {
                setPlayerState(old => old && { ...old, userIsQueued: false });
            });

            hubConnection.on("SetHasConfirmation", () => {
                setPlayerState(
                    old =>
                        old && {
                            ...old,
                            userIsQueued: false,
                            confirmation: {
                                userHasConfirmation: true,
                                opponentHasConfirmed: false,
                                userHasConfirmed: false,
                            },
                        },
                );
            });

            hubConnection.on("SetPlayerMatchConfirmed", (opponentName: string) => {
                setPlayerState(
                    old =>
                        old?.confirmation && {
                            ...old,
                            confirmation: { ...old.confirmation, userHasConfirmed: true, opponentName },
                        },
                );
            });

            hubConnection.on("SetOpponentMatchConfirmed", () => {
                setPlayerState(
                    old =>
                        old?.confirmation && {
                            ...old,
                            confirmation: { ...old.confirmation, opponentHasConfirmed: true },
                        },
                );
            });

            hubConnection.on("EntrantDeclinedMatch", () => {
                setShowEntrantDeclinedMatch(true);
                setPlayerState(old => old && { ...old, confirmation: undefined });
            });

            hubConnection.on("OpponentDeclinedMatch", () => {
                setShowOpponentDeclinedMatch(true);
                setPlayerState(old => old && { ...old, confirmation: undefined });
            });

            hubConnection.on("UpdatePlayerState", (state: GetLadderPlayerStateResult) => {
                setPlayerState(state);
            });

            hubConnection.on("CompleteMatch", () => {
                setIsMatchCompleted(true);
            });

            hubConnection.on("UpdateChallenge", (challenge?: GetLadderPlayerStateChallengeResult) => {
                hideActiveModals();

                setPlayerState(old => old && { ...old, challenge });
            });

            hubConnection.on("ShowChallengeDeclined", () => {
                setShowChallengeDeclined(true);
            });

            hubConnection.on("UpdateCancellation", (cancellation?: GetLadderPlayerStateCancellationResult) => {
                setPlayerState(old => old && { ...old, cancellation });
            });

            hubConnection.on("ShowCancellationDeclined", () => {
                setShowCancellationDeclined(true);
            });

            hubConnection.on("ShowMatchCancelled", () => {
                setShowMatchCancelled(true);
            });

            hubConnection.on("ShowMatchAutoCancelled", () => {
                setShowMatchAutoCancelled(true);
            });

            hubConnection.on("UpdatePairing", (pairing?: GetLadderPlayerStatePairingResult) => {
                setPlayerState(old => old && { ...old, pairing });
            });

            hubConnection.on("UpdateConfirmation", (confirmation?: GetLadderPlayerStateConfirmationResult) => {
                setPlayerState(old => old && { ...old, confirmation });
            });

            hubConnection.on("UpdatePairingEntrantBlindCharacter", (characterId: number) => {
                setPlayerState(
                    old =>
                        old?.pairing && {
                            ...old,
                            pairing: { ...old.pairing, chosenCharacterId: characterId },
                        },
                );
            });

            hubConnection.on("UpdatePairingOpponentBlindCharacter", (characterId: number) => {
                setPlayerState(
                    old =>
                        old?.pairing && {
                            ...old,
                            pairing: { ...old.pairing, opponentCharacterId: characterId },
                        },
                );
            });

            hubConnection.on("ShowMatchResult", (result: LadderMatchCompletedResult) => {
                hideActiveModals();
                setCompletedResult(result);
                setIsMatchReporting(false);
            });

            hubConnection.on("NotifyChallengeReceived", (challengeId: number, opponentName: string) => {
                notify({
                    title: "Challenge received",
                    body: `${opponentName} wants to fight`,
                    actions: [
                        { action: "accept-challenge", title: "Accept" },
                        { action: "reject-challenge", title: "Reject" },
                    ],
                    data: {
                        acceptUrl: RouteLink.notificationAcceptChallenge(ladder?.ladderId, challengeId),
                        rejectUrl: RouteLink.notificationRejectChallenge(ladder?.ladderId, challengeId),
                        lobbyUrl: RouteLink.ladderLobby(ladder?.ladderId),
                    },
                });
            });

            hubConnection.on("NotifyQueueConfirmationReceived", () => {
                notify({
                    title: "Ladder match ready",
                    body: "Accept your queue match",
                    actions: [
                        { action: "accept-queue", title: "Accept" },
                        { action: "reject-queue", title: "Reject" },
                    ],
                    data: {
                        acceptUrl: RouteLink.notificationAcceptQueue(ladder?.ladderId),
                        rejectUrl: RouteLink.notificationRejectQueue(ladder?.ladderId),
                        lobbyUrl: RouteLink.ladderLobby(ladder?.ladderId),
                    },
                });
            });

            // start connection
            startHub();

            hub.current = hubConnection;
            setHubState(HubConnectionState.Connected);

            // cleanup
            return () => {
                if (ladderState) {
                    consoleLog("Stopping hub connection");
                    setIsConnectingToLadder(false);
                    hubConnection.stop();
                }
            };
        }
    }, [handlePreconnect, ladder, ladderState]);

    // register for ladder
    const setUserHasEntered = () => {
        setLadder(old => old && { ...old, userHasEntered: true });
    };

    // leave match
    const leaveMatch = () => {
        setPlayerState(old => old && { ...old, pairing: undefined });
    };

    // reset if match is complete on load
    useEffect(() => {
        if (playerState?.pairing?.pairingId) {
            setIsMatchCompleted(false);
        }
    }, [playerState?.pairing?.pairingId]);

    const stopHub = () => {
        consoleLog("Stopping hub");

        hub.current?.stop();
    };

    return {
        setIsConnectingToLadder,
        setLadder,
        ladder,
        ladderState,
        playerState,
        ladderStatus,
        isConnectingToLadder,
        setUserHasEntered,
        resetLadderState,
        leaveMatch,
        stopHub,
        isMatchCompleted,
        setIsMatchCompleted,
        isMatchReporting,
        setIsMatchReporting,
        showChallengeDeclined,
        setShowChallengeDeclined,
        showCancellationDeclined,
        setShowCancellationDeclined,
        showMatchCancelled,
        setShowMatchCancelled,
        showMatchAutoCancelled,
        setShowMatchAutoCancelled,
        showEntrantDeclinedMatch,
        setShowEntrantDeclinedMatch,
        showOpponentDeclinedMatch,
        setShowOpponentDeclinedMatch,
        completedResult,
        setCompletedResult,
        debugMessages,
        hubState,
    };
};

// automatic exports
export type ILadderContext = ReturnType<typeof useLadderContext>;
export const LadderContext = createContext<ILadderContext>({} as ILadderContext);

type Props = {
    children: ReactNode;
};

export const LadderContextProvider = (props: Props) => {
    const ladderContext = useLadderContext();
    return <LadderContext.Provider value={ladderContext}>{props.children}</LadderContext.Provider>;
};
