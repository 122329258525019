import "scss/app.scss";
import App from "./App";
import { createRoot } from "react-dom/client";
import "bootstrap";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const container = document.getElementById("root");

if (container) {
    const root = createRoot(container);

    root.render(<App />);
}

serviceWorkerRegistration.register({
    onUpdate: (registration: ServiceWorkerRegistration) => {
        if (window.confirm("New version available.  Reload?")) {
            registration.unregister().then(() => {
                window.location.reload();
            });
        }
    },
});
