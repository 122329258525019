import { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { RouteList } from "../Routes";
import { GlobalContext } from "../contexts/GlobalContext";
import Brand from "assets/img/logo192.png";
import { LadderContext } from "contexts/LadderContext";
import { Collapse } from "bootstrap";

export const Navbar = () => {
    const { isAuthenticated, logout } = useContext(GlobalContext);
    const { resetLadderState } = useContext(LadderContext);

    const handleLogout = () => {
        logout();
        resetLadderState();
        Collapse.getOrCreateInstance("#subnav", { toggle: false }).hide();
    };

    return (
        <nav className="navbar navbar-expand-lg bg-body-secondary">
            <div className="container">
                <Link to={RouteList.Home} className="navbar-brand hstack gap-2">
                    <img alt="" src={Brand} style={{ height: 24 }} />

                    <span className="fw-bolder">Ladders</span>
                </Link>

                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbar-content"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse justify-content-between" id="navbar-content">
                    <ul className="navbar-nav me-auto"></ul>

                    <ul className="navbar-nav">
                        <li className="nav-item">
                            {isAuthenticated ? (
                                <button type="button" className="nav-link" onClick={handleLogout}>
                                    Log out
                                </button>
                            ) : (
                                <NavLink to={RouteList.AccountLogin} className="nav-link">
                                    Log in
                                </NavLink>
                            )}
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};
