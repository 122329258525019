import { CharacterDto } from "dtos/CharacterDto";
import { useState, useCallback, useEffect } from "react";

export const useCharactersData = (props: { data?: Array<CharacterDto> }) => {
    const { data } = props;

    const [characters, setCharacters] = useState<Array<CharacterDto>>();
    const [charactersDictionary, setCharactersDictionary] = useState<Map<number, CharacterDto>>(
        new Map<number, CharacterDto>(),
    );

    const getCharacter = useCallback((id: number) => charactersDictionary.get(id), [charactersDictionary]);
    const getCharactersForGame = useCallback(
        (gameId: number) => characters?.filter(x => x.gameId === gameId),
        [characters],
    );

    useEffect(() => {
        if (data) {
            setCharacters(data);
            setCharactersDictionary(new Map(data.map(g => [g.id, g])));
        }
    }, [data]);

    return {
        characters,
        getCharacter,
        getCharactersForGame,
    };
};
