import { Route, Switch } from "react-router-dom";
import { RouteTable } from "Routes";
import { Suspense } from "react";
import { Navbar } from "./Navbar";
import { Subnav } from "./Subnav";
import { PagePlaceholder } from "placeholder/PagePlaceholder";
import { DebugFooter } from "./DebugFooter";
import { ReconnectingBanner } from "./ReconnectingBanner";

export const Routes = () => (
    <div className="flex-grow-1 vstack">
        <main id="main" className="d-flex flex-grow-1 flex-column gap-4 pb-5">
            <div className="vstack flex-grow-0">
                <Navbar />
                <Subnav />
                <ReconnectingBanner />
            </div>

            <div className="container">
                <Suspense fallback={<PagePlaceholder />}>
                    <Switch>
                        {RouteTable.map(({ path, Component }) => (
                            <Route exact path={path} key={path}>
                                {({ match }) => <Component {...match?.params} key={window.location.search} />}
                            </Route>
                        ))}
                    </Switch>
                </Suspense>
            </div>
        </main>

        <DebugFooter />
    </div>
);
