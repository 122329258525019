import { Collapse } from "bootstrap";
import { Fragment, useContext, useEffect } from "react";
import { LadderContext } from "contexts/LadderContext";
import { useHistory } from "react-router-dom";
import { RouteList } from "Routes";

export const Subnav = () => {
    const { ladder, resetLadderState, isConnectingToLadder, stopHub } = useContext(LadderContext);

    const history = useHistory();

    useEffect(() => {
        if (isConnectingToLadder) {
            Collapse.getOrCreateInstance("#subnav").show();
        }
    }, [isConnectingToLadder]);

    const reset = () => {
        resetLadderState();
        stopHub();
        Collapse.getOrCreateInstance("#subnav").hide();
        history.push(RouteList.Home);
    };

    return (
        <div className="collapse" id="subnav">
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container">
                    <span className="navbar-brand flex-grow-1 text-truncate" style={{ flexBasis: "200px" }}>
                        {ladder ? (
                            <span className="fw-bolder">{ladder.ladderName}</span>
                        ) : (
                            <Fragment>Connecting to ladder...</Fragment>
                        )}
                    </span>

                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#subnav-content"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse justify-content-end" id="subnav-content">
                        <ul className="navbar-nav gap-2 flex-row mt-2 mt-lg-0 justify-content-lg-center">
                            <li className="nav-item">
                                <button type="button" className="btn btn-link px-0 px-lg-1" onClick={reset}>
                                    Disconnect
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
};
