import { useState, useCallback } from "react";

export const useErrors = () => {
    const [error, setError] = useState<string>();

    // globally handle server errors
    const catchServerError = useCallback((e: unknown) => {
        // eslint-disable-next-line no-console
        console.log("Website error:");
        // eslint-disable-next-line no-console
        console.log(e);
        setError("An error occurred");
    }, []);

    return {
        error,
        catchServerError,
    };
};
