import { CommandResult } from "results/CommandResult";
import { GetLaddersCommonDataResult } from "results/ladders/GetLaddersCommonDataResult";
import { UsersLoginResult } from "results/account/UsersLoginResult";
import { UsersLoginCommand } from "commands/account/UsersLoginCommand";
import { GetLaddersResult } from "results/ladders/GetLaddersResult";
import { getToken } from "hooks/useAuth";
import { GetLadderResult } from "results/ladders/GetLadderResult";
import { GetLadderMatchesResult } from "results/ladders/GetLadderMatchesResult";
import { GetLadderLeaderboardResult } from "results/ladders/GetLadderLeaderboardResult";
import { GetLadderCharactersResult } from "results/ladders/GetLadderCharactersResult";
import { GetLadderEntrantResult } from "results/ladders/GetLadderEntrantResult";
import { LaddersPostLadderChatMessageCommand } from "commands/ladders/LaddersPostChatMessageCommand";
import { GetLadderConnectionResult } from "results/ladders/GetLadderConnectionResult";
import { LaddersRecordWinCommand } from "commands/ladders/LaddersRecordWinCommand";
import { GetLadderEntrantsToChallengeResult } from "results/ladders/GetLadderEntrantsToChallengeResult";
import { LaddersBlindSelectCharacterCommand } from "commands/ladders/LaddersBlindSelectCharacterCommand";
import { GetReportingLadderMatchesResult } from "results/reporting/GetReportingLadderMatchesResult";
import { GetReportingLadderEntrantsResult } from "results/reporting/GetReportingLadderEntrantsResult";

const getApiUrl = (url: string) => `/api${url}`;

const getHeaders = (useJson = true) => {
    const h = new Headers();

    if (useJson) {
        h.append("Content-Type", "application/json");
    }

    const token = getToken();

    if (token) {
        h.append("Authorization", `Bearer ${token}`);
    }

    return h;
};

async function submitForm<TResult>(method: string, url: string, data?: unknown) {
    const body = JSON.stringify(data);

    const x = await fetch(getApiUrl(url), {
        method: method,
        body,
        headers: getHeaders(),
    });

    return await (x.json() as Promise<TResult>);
}

async function get<T>(url: string) {
    const x = await fetch(getApiUrl(url), {
        method: "GET",
        headers: getHeaders(),
    });

    return await (x.json() as Promise<T>);
}

async function post<T>(url: string, data?: unknown) {
    return submitForm<CommandResult<T>>("POST", url, data);
}

export class ApiService {
    // account
    login(model: UsersLoginCommand) {
        return post<UsersLoginResult>("/account/login", model);
    }

    // global data
    getGlobalData() {
        return get<GetLaddersCommonDataResult>("/home/data");
    }

    getLadders() {
        return get<GetLaddersResult>("/home/ladders");
    }

    preconnect(id: number) {
        return get<GetLadderConnectionResult>(`/home/preconnect/${id}`);
    }

    getLadder(id: number) {
        return get<GetLadderResult>(`/home/ladder/${id}`);
    }

    getLadderMatches(id: number) {
        return get<GetLadderMatchesResult>(`/home/ladder/${id}/matches`);
    }

    getLadderLeaderboard(id: number) {
        return get<GetLadderLeaderboardResult>(`/home/ladder/${id}/leaderboard`);
    }

    getLadderCharacters(id: number) {
        return get<GetLadderCharactersResult>(`/home/ladder/${id}/characters`);
    }

    getLadderEntrant(id: number) {
        return get<GetLadderEntrantResult>(`/home/entrant/${id}`);
    }

    getLadderEntrantsToChallenge(id: number) {
        return get<GetLadderEntrantsToChallengeResult>(`/home/ladder/${id}/entrants-to-challenge`);
    }

    getLadderMatchesReport(id: number) {
        return get<GetReportingLadderMatchesResult>(`/home/ladder/${id}/report/matches`);
    }

    getLadderEntrantsReport(id: number) {
        return get<GetReportingLadderEntrantsResult>(`/home/ladder/${id}/report/entrants`);
    }

    // post
    registerForLadder(id: number) {
        return post("/home/register", { id });
    }

    postLadderChatMessage(model: LaddersPostLadderChatMessageCommand) {
        return post("/home/ladder-chat", model);
    }

    postPairingChatMessage(model: LaddersPostLadderChatMessageCommand) {
        return post("/home/pairing-chat", model);
    }

    queue(id: number) {
        return post("/home/queue", { id });
    }

    unqueue(id: number) {
        return post("/home/unqueue", { id });
    }

    acceptQueueMatch(id: number) {
        return post("/home/accept-queue-match", { id });
    }

    rejectQueueMatch(id: number) {
        return post("/home/reject-queue-match", { id });
    }

    challengePlayer(id: number) {
        return post("/home/challenge-player", { id });
    }

    acceptChallenge(id: number) {
        return post("/home/accept-challenge", { id });
    }

    rejectChallenge(id: number) {
        return post("/home/reject-challenge", { id });
    }

    cancelChallenge(id: number) {
        return post("/home/cancel-challenge", { id });
    }

    cancelMatch(id: number) {
        return post("/home/cancel-match", { id });
    }

    acceptCancelMatch(id: number) {
        return post("/home/accept-cancel-match", { id });
    }

    rejectCancelMatch(id: number) {
        return post("/home/reject-cancel-match", { id });
    }

    cancelCancelMatch(id: number) {
        return post("/home/cancel-cancel-match", { id });
    }

    forfeit(id: number) {
        return post("/home/forfeit", { id });
    }

    recordWin(command: LaddersRecordWinCommand) {
        return post("/home/record-win", command);
    }

    blindSelectCharacter(command: LaddersBlindSelectCharacterCommand) {
        return post("/home/blind-select-character", command);
    }
}
