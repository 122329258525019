import { useState, useEffect } from "react";
import { RouteList } from "Routes";
import { useHistory } from "react-router-dom";

const storage = localStorage.getItem("Shitashi") ? sessionStorage : localStorage;

export const authTokenKey = "auth-token";

export const getToken = () => storage.getItem(authTokenKey) ?? "";
export const accessTokenFactory = () => getToken();

export const useAuth = () => {
    const history = useHistory();

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    // account methods
    const login = (token: string) => {
        setIsAuthenticated(true);
        localStorage.setItem(authTokenKey, token);
        history.push(RouteList.Home);
    };

    const logout = () => {
        setIsAuthenticated(false);
        localStorage.removeItem(authTokenKey);
        history.push(RouteList.Home);
    };

    // the user is logged in if they have the token saved in localStorage on page load
    useEffect(() => {
        const token = localStorage.getItem(authTokenKey);

        if (token) {
            setIsAuthenticated(true);
        }
    }, []);

    return {
        isAuthenticated,
        login,
        logout,
    };
};
