import { useAuth } from "hooks/useAuth";
import { useCharactersData } from "hooks/useCharactersData";
import { useErrors } from "hooks/useErrors";
import { useGamesData } from "hooks/useGamesData";
import { useTheme } from "hooks/useTheme";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GetLaddersCommonDataResult } from "results/ladders/GetLaddersCommonDataResult";
import { ApiService } from "services/ApiService";

// api access
const service = new ApiService();

const suffixes = new Map([
    ["one", "st"],
    ["two", "nd"],
    ["few", "rd"],
    ["other", "th"],
]);

// ordinals
const formatter = new Intl.PluralRules("en-US", { type: "ordinal" });
const numberToOrdinal = (n: number) => {
    const suffix = suffixes.get(formatter.select(n));
    return `${n}${suffix}`;
};

export const useGlobalContext = () => {
    // dark mode
    useTheme();

    // standard imports
    const { login, logout, isAuthenticated } = useAuth();
    const { error, catchServerError } = useErrors();

    // data
    const [commonData, setCommonData] = useState<GetLaddersCommonDataResult>();

    useEffect(() => {
        let isMounted = true;
        service
            .getGlobalData()
            .then(x => {
                if (isMounted) {
                    setCommonData(x);
                }
            })
            .catch(catchServerError);

        return () => {
            isMounted = false;
        };
    }, [catchServerError]);

    // data access
    const { games, getGame } = useGamesData({ data: commonData?.games });
    const { characters, getCharacter, getCharactersForGame } = useCharactersData({ data: commonData?.characters });

    // scroll to top on page change
    const location = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, [location]);

    return {
        isAuthenticated,
        login,
        logout,
        service,
        numberToOrdinal,
        error,
        catchServerError,
        games,
        getGame,
        characters,
        getCharacter,
        getCharactersForGame,
    };
};

// automatic exports
export type IGlobalContext = ReturnType<typeof useGlobalContext>;
export const GlobalContext = createContext<IGlobalContext>({} as IGlobalContext);

type Props = {
    children: ReactNode;
};

export const GlobalContextProvider = (props: Props) => {
    const globalContext = useGlobalContext();
    return <GlobalContext.Provider value={globalContext}>{props.children}</GlobalContext.Provider>;
};
